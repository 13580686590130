<template>
  <div class="home">
    <img src="../assets/logo_2023.png" class="mainLogo">
  </div>
  <div class="countdown">
    <Countdown deadline="June 15, 2026"></Countdown>
  </div>
</template>

<script>
// @ is an alias to /src
import Countdown from '@/components/Countdown.vue';

export default {
  name: 'HomeView',
  components: {
    Countdown
  }
}

</script>

<style>
.mainLogo{
  width: 100vw;
}
.countdown{
  margin-top: 75px;
}
.countdown .digit{
  font-size: 3em;
  color: rgba(77,77,77, 0.89);
}
.countdown .text{
  color: cadetblue;
}

@media all and (min-width: 620px){
  .mainLogo{
    width: 620px;
  }
}
</style>
